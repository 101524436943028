@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply px-8 md:px-16 lg:px-0 max-w-6xl mx-auto;
  }
}

@layer utilities {
  .truncate-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

}

html {
  font-family: 'IBM Plex Sans', sans-serif;
}
